<form [formGroup]="form">
  <div class="mat-h3">Sökande och hushåll</div>
  <mat-checkbox
    color="primary"
    formControlName="hasCoApplicant"
    ngDefaultControl>
    Är ni fler sökande?
  </mat-checkbox>

  @if (hasCoApplicant) {
    <mat-checkbox
      color="primary"
      formControlName="areApplicantsMarried"
      ngDefaultControl>Är ni som tillsammans söker lån gifta, partners eller
      sambo?
    </mat-checkbox>
  }

  <p></p>

  <!-- Personal numbers -->
  <ng-container>
    @for (applicant of applicants; track $index) {
      <p class="lead-text">
        Personnummer {{ $first ? 'huvudlåntagare' : 'medlåntagare' }}
      </p>

      <!-- Haxx -->
      <mat-form-field class="standard-full-width" [style.margin-bottom.px]="15"
                      subscriptSizing="dynamic">
        <input
          [formControl]="applicant.controls.personalNumber"
          matInput
          placeholder="ÅÅÅÅMMDD-NNNN"
          spbPersonnummerValidator
          [mustBeOver18]="true"
          [otherPersonnummers]="[$first ? coApplicantPersonalNumber : applicantPersonalNumber]"
          type="tel"
        >
        <mat-error>
          {{ applicant.controls.personalNumber.errors?.error }}
        </mat-error>
      </mat-form-field>
    }
  </ng-container>

  <!-- Children -->
  <ng-container>
    <p class="lead-text">Antal hemmavarande barn under 18 år</p>
    <mat-form-field class="standard-full-width">
      <mat-label>Välj i listan</mat-label>
      <mat-select [formControl]="form.controls.numberOfChildren"
                  ngDefaultControl>
        <mat-option [disabled]="true" [value]="null">Välj i listan</mat-option>
        <mat-option [value]="0">Inga</mat-option>
        @for (count of [1, 2, 3, 4, 5, 6]; track count) {
          <mat-option [value]="count">
            {{ count }}
          </mat-option>
        }
      </mat-select>
      <mat-error>
        Du måste välja ett alternativ.
      </mat-error>
    </mat-form-field>

    @for (child of children; track $index) {
      <div>
        <mat-form-field>
          <mat-label>Ålder på
            barn{{ $count === 1 ? 'et' : (' ' + ($index + 1)) }}
          </mat-label>
          <mat-select
            [formControl]="child.controls.age"
            ngDefaultControl>
            <mat-option [value]="null" [disabled]="true">Välj i listan
            </mat-option>
            @for (age of ages; track age) {
              <mat-option [value]="age">
                {{ age | number: '1.0-0' }}
              </mat-option>
            }
          </mat-select>
          <mat-error>
            Du måste välja ett alternativ.
          </mat-error>
        </mat-form-field>
      </div>
    }


    <!-- Barnomsorg -->
    <p class="lead-text">Har {{ hasCoApplicant ? 'ni' : 'du' }}
      kostnader för barnomsorg?</p>
    <mat-form-field class="standard-full-width">
      <mat-label>Välj i listan</mat-label>
      <mat-select [formControl]="form.controls.hasChildrenCareCost"
                  ngDefaultControl>
        <mat-option [disabled]="true" [value]="null">Välj i listan
        </mat-option>
        <mat-option [value]=true>Ja</mat-option>
        <mat-option [value]="false">Nej</mat-option>
      </mat-select>
      @if (form.controls.hasChildrenCareCost.hasError('required')) {
        <mat-error>
          Du måste välja ett alternativ.
        </mat-error>
      }
    </mat-form-field>

    <!-- Children care cost monthly cost -->
    @if (form.controls.hasChildrenCareCost.value === true) {
      <div>
        <p class="lead-text">Ange {{ hasCoApplicant ? 'era' : 'dina' }}
          faktiska
          kostnader för barnomsorg i kronor<strong>/månad</strong></p>
        <mat-form-field class="standard-full-width">
          <label><input
            [formControl]="form.controls.monthlyChildrenCareCost"
            inputmode="numeric"
            matInput
            placeholder="Kostnad barnomsorg kronor/månad"
            spbFormatNumber>
          </label>
        </mat-form-field>
      </div>
    }


  </ng-container>

  <!-- Applicants' partners -->
  @if (!form.controls.areApplicantsMarried.value) {
    <ng-container>
      @for (applicant of applicants; track $index) {
        <p class="lead-text">
          Är {{ $first ? 'du' : 'medsökande' }} gift, har en registrerad partner
          eller är sambo?
        </p>
        <mat-form-field class="standard-full-width">
          <mat-label>Välj i listan</mat-label>
          <mat-select [formControl]="applicant.controls.isMarried"
                      ngDefaultControl>
            <mat-option [value]=true>Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
        </mat-form-field>

        @if (applicant.controls.isMarried.value) {
          <div>
            <mat-form-field class="standard-full-width"
                            [style.margin-bottom.px]="15"
                            subscriptSizing="dynamic">
              <input [formControl]="applicant.controls.partnerPersonalNumber"
                     matInput
                     placeholder="Ange dennes personnummer"
                     spbPersonnummerValidator
                     [mustBeOver18]="true"
                     [otherPersonnummers]="$first ? [applicantPersonalNumber] : [coApplicantPersonalNumber]"
                     type="tel">
              <mat-error>{{ applicant.controls.partnerPersonalNumber.errors?.error }}</mat-error>
            </mat-form-field>
          </div>
        }
      }
    </ng-container>
  }

  <!-- Car -->
  <ng-container>
    <!-- Has car? -->
    <p class="lead-text">Äger {{ hasCoApplicant ? 'ni' : 'du' }} bil?</p>
    <mat-form-field class="standard-full-width">
      <mat-label>Välj i listan</mat-label>
      <mat-select [formControl]="form.controls.hasCar" ngDefaultControl>
        <mat-option [disabled]="true" [value]="null">Välj i listan</mat-option>
        <mat-option [value]=true>Ja</mat-option>
        <mat-option [value]="false">Nej</mat-option>
      </mat-select>
      <mat-error>
        Du måste välja ett alternativ.
      </mat-error>
    </mat-form-field>

    <!-- Number of cars -->
    @if (form.controls.hasCar.value) {
      <div>
        <p class="lead-text">Ange antal bilar {{ hasCoApplicant ? 'ni' : 'du' }}
          äger</p>
        <mat-form-field class="standard-full-width">
          <mat-label>Välj i listan</mat-label>
          <mat-select [formControl]="form.controls.numberOfCars"
                      ngDefaultControl>
            <mat-option [value]="null" [disabled]="true">Välj ett alternativ
            </mat-option>
            @for (carCount of [1, 2, 3]; track carCount) {
              <mat-option [value]="carCount">
                {{ carCount }}
              </mat-option>
            }
          </mat-select>
          <mat-error>
            Ange antal bilar
          </mat-error>
        </mat-form-field>
      </div>
    }
  </ng-container>

  <!-- Lease car -->
  <ng-container>
    <!-- Has lease car? -->
    <p class="lead-text">Privatleasar {{ hasCoApplicant ? 'ni' : 'du' }}
      bil?</p>
    <mat-form-field class="standard-full-width">
      <mat-label>Välj i listan</mat-label>
      <mat-select [formControl]="form.controls.hasLeaseCar" ngDefaultControl>
        <mat-option [disabled]="true" [value]="null">Välj i listan</mat-option>
        <mat-option [value]=true>Ja</mat-option>
        <mat-option [value]="false">Nej</mat-option>
      </mat-select>
      @if (form.controls.hasLeaseCar.hasError('required')) {
        <mat-error>
          Du måste välja ett alternativ.
        </mat-error>
      }
    </mat-form-field>

    <!-- Lease car monthly cost -->
    @if (form.controls.hasLeaseCar.value === true) {
      <div>
        <p class="lead-text">Ange {{ hasCoApplicant ? 'era' : 'dina' }} faktiska
          kostnader för privatleasad
          bil. Ange hushållets kostnad i kronor<strong>/månad</strong></p>
        <mat-form-field class="standard-full-width">
          <label><input
            [formControl]="form.controls.leaseCarCost"
            inputmode="numeric"
            matInput
            placeholder="Kostnad privatleasing bil kronor/månad"
            spbFormatNumber>
          </label>
        </mat-form-field>
      </div>
    }
  </ng-container>
</form>
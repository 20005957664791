import {KeyValuePipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {LoanType} from '@sparbanken-syd/loan-backend'
import {LoanTypeMap} from '@sparbanken-syd/loan-backend/dist/shared'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {FormUtils} from '../../utils/form.utils'
import {IApplicantsForm} from '../lanelafte-step-1/lanelafte-step-1.component'

export const getExistentLoansForm = () => new FormGroup(({
  studentLoan: new FormControl(null, Validators.max(10 * 1000)),
  hasOtherLoans: new FormControl(false, {nonNullable: true}),
  numberOfLoans: new FormControl(0, {nonNullable: true}),
  otherLoans: new FormArray<FormGroup>([])
} as IExistentLoansForm))

export const getOtherLoanForm = () => new FormGroup(({
  amount: new FormControl(null, [Validators.required, Validators.min(1)]),
  type: new FormControl(null, [Validators.required, Validators.min(1)])
} as IOtherLoanGroup))

export interface IExistentLoansForm {
  studentLoan: FormControl<number | null>,
  hasOtherLoans: FormControl<boolean>,
  numberOfLoans: FormControl<number>,
  otherLoans: FormArray<FormGroup<IOtherLoanGroup>>
}

export interface IOtherLoanGroup {
  amount: FormControl<number | null>
  type: FormControl<LoanType | null>
}

@Component({
  selector: 'spb-lanelafte-step-4',
  templateUrl: './lanelafte-step-4.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss', './lanelafte-step-4.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatCheckbox, MatSelect, MatOption, KeyValuePipe, FormatNumberDirective]
})
export class LanelafteStep4Component implements OnInit {
  @Input() form = new FormGroup<IExistentLoansForm>(({} as IExistentLoansForm))

  @Input() applicantsForm = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  public readonly loanTypes = LoanTypeMap

  /**
   * Form getters for easy access
   */
  get hasCoApplicant(): boolean {
    return this.applicantsForm.controls.hasCoApplicant.value
  }

  get otherLoans(): FormGroup<IOtherLoanGroup>[] {
    return this.form.controls.otherLoans.controls
  }

  ngOnInit() {
    this.form.controls.hasOtherLoans.valueChanges
      .subscribe((value: boolean) => {
        if (value) {
          // Create fist loan
          this.addLoan()
        } else {
          // Remove all loans
          while (this.form.controls.otherLoans.length > 0) {
            this.removeLoan(this.form.controls.otherLoans.length - 1)
          }
        }
      })

    this.form.controls.numberOfLoans.valueChanges
      .subscribe((value: number) => {
        // Whenever there are loans imported from a saved application in LocalStorage the form needs to be initialised
        // That's why it will only be used to "create more than there are now" and never to remove
        if (value > this.otherLoans.length) {
          FormUtils.modifyNumberOfGroups(this.form.controls.otherLoans, value, getOtherLoanForm)
        }
      })
  }

  public addLoan() {
    this.form.controls.numberOfLoans.setValue(this.form.controls.numberOfLoans.value + 1)
  }

  public removeLoan(index: number) {
    this.form.controls.otherLoans.removeAt(index)
    this.form.controls.numberOfLoans.setValue(this.form.controls.numberOfLoans.value - 1)
  }

}

/**
 * Shared is shared functions that are shared with the FE.
 */
import { LoanType, OccupationType, PropertyType } from './types';
/**
 * Returns false if any applicant has a no superfast occupation
 */
export const superFastOccupation = application => {
  return !application.applicants.some(a => ![OccupationType.PERMANENT, OccupationType.RETIRED].includes(a.occupation));
};
/**
 * Returns false if the old property is intend to sell
 */
export const superFastOldProperty = application => {
  if (!application.oldProperty) {
    return true;
  }
  return !application.oldProperty.futurePropertyOwnership?.includes('intendToSell');
};
/**
 * There are some conditions that have to be fulfilled in order to do a superfast
 * application. First out is occupation and second one is "intend to sell" old property.
 *
 * We expect a sanitized application with the basics in place.
 *
 * Occupations PERMANENT(0) and RETIRED(1) are ok.
 * @param application
 */
export const checkIfSuperFastIsPossible = application => {
  let result = superFastOccupation(application);
  result = result && superFastOldProperty(application);
  return result;
};
export const LoanTypeMap = new Map([[LoanType.HOUSING, 'Bostad'], [LoanType.BLANCO, 'Blanco'], [LoanType.CARD_CREDIT, 'Kortkredit']]);
export const PropertyTypeMap = new Map([[PropertyType.VILLA, 'Villa'], [PropertyType.FRITIDSHUS, 'Fritidshus'], [PropertyType.BOSTADSRATT, 'Bostadsrätt'], [PropertyType.HYRES, 'Hyresrätt']]);
export const OccupationTypeMap = new Map([[OccupationType.PERMANENT, 'Tillsvidare'], [OccupationType.TIME_LIMITED, 'Visstid'], [OccupationType.HOURLY, 'Timanställd'], [OccupationType.OWN_COMPANY, 'Egen företagare'], [OccupationType.STUDENT, 'Student'], [OccupationType.RETIRED, 'Pensionär'], [OccupationType.UNEMPLOYED, 'Arbetssökande']]);
export const OldPropertyStatusMap = new Map([['noSell', 'Jag har inget eget boende idag'], ['confirmedContract', 'Jag har ett ovillkorat säljkontrakt på mitt befintliga boende'], ['intendToSell', 'Jag avser att sälja mitt befintliga boende, men har inget ovillkorat säljkontrakt ännu'], ['intendToSellEditable', 'Avser att sälja, räkna på kontantinsats'], ['keepingProperty', 'Jag äger ett boende som jag avser att behålla'], ['rentalLeave', 'Jag bor i en hyresrätt som jag inte avser att behålla'], ['rentalKeep', 'Jag bor i en hyresrätt som jag avser att behålla']]);
/**
 * Constants for uc thresholds
 */
export const ucLowestThreshold = 180000;
export const blancoHighestThreshold = 250000;

import {Component} from '@angular/core'
import {Router, RouterLink} from '@angular/router'
import {
  BankIdComponent,
  HelperService,
  TokenPayload
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../../environments/environment'
import {
  BreadcrumbsComponent
} from '../../common/breadcrumbs/breadcrumbs.component'
import {HeaderComponent} from '../../common/header/header.component'
import {LoanService} from '../../services/loan.service'

/**
 * Simple component to let the user identify and fetch
 * an existing loan promise.
 */
@Component({
  selector: 'spb-lanelofte-fetch',
  templateUrl: './lanelofte-fetch.component.html',
  imports: [HeaderComponent, BreadcrumbsComponent, BankIdComponent, RouterLink]
})
export class LanelofteFetchComponent {
  /**
   * Detects if we cannot find the loan promise.
   */
  public showMissing = false

  protected readonly environment = environment

  /**
   * Constructor
   * @param loanService - To fetch the loan promise
   * @param router - To route if we have a match.
   */
  constructor(
    private loanService: LoanService,
    private router: Router
  ) {
  }

  public onLoginSuccess(accessToken: string) {
    // Set receiving token in LoanService to then execute calls
    this.loanService.setToken(accessToken)

    // Get payload from token and request a "check call" to see if there is
    // any open application for the logged personnummer
    const payload = HelperService.GetTokenPayload(accessToken) as TokenPayload
    this.loanService.check(payload.sub)
      .subscribe({
        next: res => {
          // If there is an application, it will navigate to result page.
          // If not, the showMissing flag will be activated
          if (res) {
            this.router.navigate(['lanelofte/lanelofte-res', res]).then()
          } else {
            this.showMissing = true
          }
        }
      })
  }
}

import {FormGroup} from '@angular/forms'
import {LoanType, PropertyType} from '@sparbanken-syd/loan-backend/dist/types'
import {ILanelofteForm} from './lanelafte.component'

export interface IApplicationInput {
  property: {
    /**
     * Type of property the application is for.
     */
    type: 'House' | 'Apartment' | 'SummerHouse'
    /**
     * The price of the property to buy.
     */
    price: number

    /**
     * The amount that will be "kontantinsats", deducted
     * from the price above.
     */
    downPayment: number
    /**
     * Set fee if known leave out or set to zero if not
     * an apartment
     */
    fee?: number
  }

  /**
   * A list of applicants 1..2
   */
  applicants: {
      /**
       * Income per month, before tax.
       */
      income: number
    }[]


  /**
   * A placeholder for household.
   */
  household: {
    /**
     * If present enter the age of each children.
     * Empty array means no children
     */
    children: number[]
    /**
     * Total number of owned cars. Set to zero if none
     */
    ownedCars: number
    /**
     * Enter the amount of leased cars. Set to zero if none
     */
    leasedCars: number
    /**
     * The total amount of study loan??
     * Normally we record the cost per household / month
     */
    studyLoan: number
    /**
     * Cost of leased cars, total per month in SEK
     * should be set to zero if no leased cars. Cannot
     * effectively be zero if leased cars > 0
     */
    leaseCarCost: number
  }

  /**
   * If any other properties to be kept after the purchase
   *
   */
  otherProperties: {
      type: 'House' | 'Apartment' | 'SummerHouse'
      loans: number
      /**
       * If type apartment then fee is mandatory.
       */
      fee?: number
    }[]

  /**
   * Other credits.
   */
  otherCredits:
    {
      /**
       * Type, pretty free from
       */
      type: 'blanco' | 'card' | 'account'

      /**
       * If any the total credit amount at time of writing.
       */
      amount: number
    }[]

}

export class LeadDoubler {
  constructor(form: FormGroup<ILanelofteForm>, data: IApplicationInput) {
    let propertyLoans = false
    form.reset()
    const typeMap = {
      'House': PropertyType.VILLA,
      'Apartment': PropertyType.BOSTADSRATT,
      'SummerHouse': PropertyType.FRITIDSHUS
    }
    const loanTypeMap = {
      'blanco': LoanType.BLANCO,
      'card': LoanType.CARD_CREDIT,
      'account': LoanType.BLANCO // Note we do not support this type
    }

    data.applicants = data.applicants || [{income: 0}]
    data.household = data.household || {children: []}
    data.property = data.property || {}
    data.otherCredits = data.otherCredits || []
    const application = {
      version: 1,
      applicantsData: {
        hasCoApplicant: data.applicants.length > 1,
        areApplicantsMarried: false,
        numberOfChildren: data.household.children.length,
        hasCar: data.household.ownedCars > 0,
        numberOfCars: data.household.ownedCars ?? null,
        hasLeaseCar: data.household.leasedCars !== 0,
        leaseCarCost: data.household.leaseCarCost ?? 0,
        applicants: [
          {
            personalNumber: null,
            isMarried: false,
            partnerPersonalNumber: null
          },
          {
            personalNumber: null,
            isMarried: false,
            partnerPersonalNumber: null
          }
        ],
        children: [
          /**
           "age": 0.1,
           "id": "1"
           */
        ]
      },
      applicantIncomesData: {
        applicantIncomes: [
          {
            monthlyIncome: data.applicants[0].income,
            occupation: null,
            employer: null,
            isAKassaMember: null
          },
          {
            monthlyIncome: 0,
            occupation: null,
            employer: null,
            isAKassaMember: null
          }
        ]
      },
      properties: {
        newProperty: {
          price: data.property.price,
          downPayment: data.property.downPayment,
          type: typeMap[data.property.type],
          fee: null
        },
        oldProperty: {
          status: null,
          loansAmount: null,
          sellingPrice: null,
          type: -1,
          fee: null
        } as any
      },
      existentLoans: {
        studentLoan: data.household.studyLoan ?? null,
        hasOtherLoans: data.otherCredits.length > 0,
        numberOfLoans: data.otherCredits.length,
        otherLoans: [
          /**
           * "amount": 12000,
           *  "type": 1
           */
        ]
      },
      contactsData: {
        contacts: [
          {
            fullName: null,
            email: null,
            phone: null
          },
          {
            fullName: null,
            email: null,
            phone: null
          }
        ]
      },
      otherInformation: {
        message: ''
      },
      termsData: {
        terms: false,
        termsManual: false
      }
    }
    application.applicantsData.children = data.household.children.map((age, index) => {
      return {
        age,
        id: index + ''
      } as never
    })

    if (data.applicants.length === 1) {
      application.applicantsData.applicants.pop()
      application.applicantIncomesData.applicantIncomes.pop()
      application.contactsData.contacts.pop()
    } else {
      application.applicantIncomesData.applicantIncomes[1].monthlyIncome = data.applicants[1].income
    }

    if (data.otherProperties?.length > 0) {
      propertyLoans = true
      application.properties.oldProperty.futurePropertyOwnership = 'keepingProperty'
      application.properties.oldProperty.type = typeMap[data.otherProperties[0].type]
      application.properties.oldProperty.loansAmount = data.otherProperties[0].loans
      application.properties.oldProperty.fee = data.otherProperties[0].fee
    }

    application.existentLoans.otherLoans = data.otherCredits.map(c => {
      return {
        amount: c.amount,
        type: loanTypeMap[c.type]
      } as never
    })
    setTimeout(() => {
      // Do patch to avoid unexpected errors as we do not know what shite
      // is passed in from LD.
      form.patchValue(application as any)
      /**
       * We have to do this on _next_ since the fields are
       * reset on the first iteration
       */
      if (propertyLoans) {
        form.controls.properties.controls.oldProperty.controls.loansAmount
          .setValue(data.otherProperties[0].loans)
        form.controls.properties.controls.oldProperty.controls.type
          .setValue(typeMap[data.otherProperties[0].type])

        if (data.otherProperties[0].type === 'Apartment') {
          form.controls.properties.controls.oldProperty.controls.fee
            .setValue(data.otherProperties[0].fee ?? 0)
        }

        if (data.property.type === 'Apartment') {
          form.controls.properties.controls.newProperty.controls.fee
            .setValue(data.property.fee ?? 0)
        }
      }
    }, 1)
  }
}


import {NgTemplateOutlet} from '@angular/common'
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core'
import {FormControl, FormGroup} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {
  MatStep,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {ActivatedRoute, Router, RouterLink} from '@angular/router'
import {TFuturePropertyOwnership} from '@sparbanken-syd/loan-backend'
import {
  checkIfSuperFastIsPossible,
  PropertyTypeMap
} from '@sparbanken-syd/loan-backend/dist/shared'
import {
  IApplication,
  IApplicationLoan,
  LLApplicant,
  LoanType,
  PropertyType
} from '@sparbanken-syd/loan-backend/dist/types'
import {
  HelperService,
  TokenPayload
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {debounceTime, filter} from 'rxjs'
import {LOCAL_STORAGE} from '../../application/app'
import {
  CURRENT_VERSION,
  STORED_APPLICATION_NAME
} from '../../application/data-types'
import {
  BreadcrumbsComponent
} from '../../common/breadcrumbs/breadcrumbs.component'
import {
  HeaderComponent,
  PreFillOption,
  RemoveOption
} from '../../common/header/header.component'
import {
  WaitDialogComponent
} from '../../common/wait-dialog/wait-dialog.component'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'
import {LoanService, UcMockType} from '../../services/loan.service'
import {FormUtils} from '../../utils/form.utils'
import {KalpUtils} from '../../utils/kalp.utils'
import {
  StepperScrollDirective
} from '../directives/stepper-scroll/stepper-scroll.directive'
import {
  getApplicantsForm,
  IApplicantsForm,
  LanelafteStep1Component
} from '../lanelafte-step-1/lanelafte-step-1.component'
import {
  getApplicantIncomesForm,
  IApplicantIncomeForm,
  LanelafteStep2Component
} from '../lanelafte-step-2/lanelafte-step-2.component'
import {
  getPropertyForm,
  IPropertiesForm,
  LanelafteStep3Component
} from '../lanelafte-step-3/lanelafte-step-3.component'
import {
  getExistentLoansForm,
  IExistentLoansForm,
  LanelafteStep4Component
} from '../lanelafte-step-4/lanelafte-step-4.component'
import {
  getContactsForm,
  IContactsFrom,
  LanelafteStep5Component
} from '../lanelafte-step-5/lanelafte-step-5.component'
import {
  getOtherInformationForm,
  IOtherInformationForm,
  LanelafteStep6Component
} from '../lanelafte-step-6/lanelafte-step-6.component'
import {
  getTermsForm,
  ITermsForm,
  LanelafteStep7Component
} from '../lanelafte-step-7/lanelafte-step-7.component'
import {LeadDoubler} from './lead-doubler'

export interface ILanelofteForm {
  version: FormControl<number>,
  applicantsData: FormGroup<IApplicantsForm>,
  applicantIncomesData: FormGroup<IApplicantIncomeForm>,
  properties: FormGroup<IPropertiesForm>,
  existentLoans: FormGroup<IExistentLoansForm>,
  contactsData: FormGroup<IContactsFrom>,
  otherInformation: FormGroup<IOtherInformationForm>,
  termsData: FormGroup<ITermsForm>,
}

@Component({
  selector: 'spb-lanelafte',
  templateUrl: './lanelafte.component.html',
  styleUrls: ['./lanelafte.component.scss'],
  imports: [HeaderComponent, BreadcrumbsComponent, RouterLink, MatStepper, StepperScrollDirective, MatButton, MatStepperPrevious, MatStepperNext, MatStep, LanelafteStep1Component, NgTemplateOutlet, LanelafteStep2Component, LanelafteStep3Component, LanelafteStep4Component, LanelafteStep5Component, LanelafteStep6Component, LanelafteStep7Component]
})
export class LanelafteComponent implements OnInit, AfterViewInit {
  @ViewChild(LanelafteStep7Component, {static: true}) step7Component!: LanelafteStep7Component

  /**
   * Indicate if we are logged in or not.
   */
  public isLoggedIn = false

  public errorMessage = ''

  /**
   * In some cases you cannot apply superfast (with Bank ID), e.g. if you are unemployed.
   */
  public superFast = true

  /**
   * Forms to be used in all the different steps
   */
  public applicantsForm = getApplicantsForm()
  public applicantIncomesForm = getApplicantIncomesForm()
  public propertyForm = getPropertyForm()
  public existentLoansForm = getExistentLoansForm()
  public contactsForm = getContactsForm()
  public otherInformationForm = getOtherInformationForm()
  public termsForm = getTermsForm()

  /**
   * The amount of time in milliseconds to wait before checking.
   * This can be abused to introduce back-off
   */
  private CHECK_EXISTING_TIMEOUT = 3000

  private lanelafteForm = new FormGroup<ILanelofteForm>({
    version: new FormControl(CURRENT_VERSION, {nonNullable: true}),
    applicantsData: this.applicantsForm,
    applicantIncomesData: this.applicantIncomesForm,
    properties: this.propertyForm,
    existentLoans: this.existentLoansForm,
    contactsData: this.contactsForm,
    otherInformation: this.otherInformationForm,
    termsData: this.termsForm
  })

  /**
   * Semaphore to avoid loading applicaiton
   * if we load from initial state
   * @private
   */
  private loadSavedApplication = true


  /**
   * Loan service for reasons and localstorage to store some items.
   *
   */
  constructor(
    private dialog: MatDialog,
    private loanService: LoanService,
    private hemnetService: HemnetService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  public ngOnInit() {

    this.route.queryParamMap
      .subscribe({
        next: (v) => {
          if (v.get('initial')) {
            try {
              this.loadSavedApplication = false
              const data = JSON.parse(window.atob(v.get('initial') as string))
              new LeadDoubler(this.lanelafteForm, data)
            } catch {
              // Ignore everything someone sent bad values
            }
          }
        }
      })

    this.isLoggedIn = this.loanService.isLoggedIn()

    this.fetchPropertyData()

    // Save form changes into local storage (no more than once every 500ms)
    this.lanelafteForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.injectedLocalStorage.setItem(STORED_APPLICATION_NAME, JSON.stringify(value))
        this.superFast = checkIfSuperFastIsPossible(this.parseFormToBackendApplication(this.lanelafteForm) as IApplication)
      })
  }

  public ngAfterViewInit(): void {
    const savedApplication = this.injectedLocalStorage.getItem(STORED_APPLICATION_NAME)
    if (savedApplication && this.loadSavedApplication) {
      const parsedSavedApplication = JSON.parse(savedApplication)
      if (parsedSavedApplication.version === CURRENT_VERSION) {
        setTimeout(() => {
          this.lanelafteForm.setValue(parsedSavedApplication)
        }, 10)
      }
    }
  }

  /**
   * Function only activated in testing environments to remove some data from the application
   */
  public removeApplicationData(option: RemoveOption) {
    KalpUtils.removeDataFromForm(option, this.lanelafteForm, this.injectedLocalStorage)
  }

  /**
   * Function only activated in testing environments to pre-fill some data into the application
   */
  public preFillApplicationData(option: PreFillOption) {
    KalpUtils.preFillFormData(option, this.lanelafteForm)
  }

  public onSignSuccess(accessToken: string) {
    const payload = HelperService.GetTokenPayload(accessToken) as TokenPayload
    // Check if sub in application (replace = only numbers) is not the same as the sub
    if (payload.sub === this.applicantsForm.controls.applicants.controls[0].controls.personalNumber.value
      .replace(/\D/g, '') || payload.roles.indexOf('admin') !== -1) {
      this.loanService.setToken(accessToken)
      this.apply()
    } else {
      this.errorMessage = 'Personnumret som identifierades via BankID matchar inte personnumret i ansökan'
    }
  }

  public apply(): void {
    const ref = this.dialog.open(WaitDialogComponent, {
      data: 'Beräknar lånelöfte...'
    })

    const application = this.parseFormToBackendApplication(this.lanelafteForm)
    if (this.loanService.isMockUcActive$() !== null) {
      KalpUtils.addMockUc(this.loanService.isMockUcActive$() as UcMockType, application)
    }

    this.loanService.apply(application).subscribe({
      next: (res: any) => {
        ref.close()
        const navigation = ['lanelofte/lanelofte-res', res['loan-promise-id']]
        return this.router.navigate(navigation)
      },
      error: (error: any) => {
        // Reset Bank ID component
        this.step7Component.bankId.reset()

        ref.close()
        if (error.status === 502) {
          this.errorMessage = 'Vi har just nu problem med att göra kreditupplysning, prova igen om en stund.'
        } else if (error.status === 401) {
          this.errorMessage = 'Din session har gått ut, du kommer att bli hänvisad till inloggningen'
          setTimeout(() => {
            this.isLoggedIn = false
          }, 3000)
        } else if (error.status === 406 || error.status === 400) {
          this.errorMessage = 'Någon uppgift du har lämnat verkar inte stämma. Vänligen kontrollera och försök igen.'
        } else if (error.status === 409) {
          this.errorMessage = 'Vi har redan en ansökan registrerad för dig. Vi kommer strax att kontrollera status.'
          setTimeout(() => {
            this.checkExisting()
          }, this.CHECK_EXISTING_TIMEOUT)
        } else if (error.status === 419) {
          return
        } else {
          this.errorMessage = 'Aj då! Nu gick något fel, prova igen om en stund.'
        }
      }
    })
  }

  private checkExisting(): void {
    this.loanService.check(this.applicantsForm.controls.applicants.controls[0].controls.personalNumber.value)
      .pipe(filter(Boolean))
      .subscribe({
        next: res => {
          this.router.navigate(['lanelofte/lanelofte-res', res]).then()
        }
      })
  }

  private fetchPropertyData(): void {
    this.hemnetService.getHemnetData()
      .subscribe({
        next: (data: IHemnetData | undefined) => {
          const hemnetData: IHemnetData = data || {}
          if (!hemnetData.expectedPrice) {
            return
          }

          this.propertyForm.controls.newProperty.controls.price.setValue(hemnetData.expectedPrice as number)
          this.propertyForm.controls.newProperty.controls.downPayment.setValue(hemnetData.downPayment as number)

          if (hemnetData.type && PropertyTypeMap.has(hemnetData.type)) {
            this.propertyForm.controls.newProperty.controls.type.setValue(hemnetData.type)

            // Fee will only be set if the type is the correct one
            if (hemnetData.type === PropertyType.BOSTADSRATT) {
              this.propertyForm.controls.newProperty.controls.fee.setValue(hemnetData.fee as number)
            }
          }
        }
      })
  }

  private parseFormToBackendApplication(form: typeof this.lanelafteForm): Partial<IApplication> {
    const application = {
      // Additional info
      version: CURRENT_VERSION,
      timeStamp: 0,

      // Step 1, 2 & 5
      coApplicant: form.controls.applicantsData.controls.hasCoApplicant.value,
      applicants: form.controls.applicantsData.controls.applicants.value
        .map((value, index): LLApplicant => {
          return {
            income: form.controls.applicantIncomesData.controls.applicantIncomes.controls[index].controls.monthlyIncome.value,
            akassa: form.controls.applicantIncomesData.controls.applicantIncomes.controls[index].controls.isAKassaMember.value,
            employer: form.controls.applicantIncomesData.controls.applicantIncomes.controls[index].controls.employer.value,
            isSpouse: value.isMarried,
            spouse: value.partnerPersonalNumber,
            personNummer: value.personalNumber,
            occupation: form.controls.applicantIncomesData.controls.applicantIncomes.controls[index].controls.occupation.value,
            contact: {
              name: form.controls.contactsData.controls.contacts.controls[index].controls.fullName.value,
              email: form.controls.contactsData.controls.contacts.controls[index].controls.email.value,
              phone: form.controls.contactsData.controls.contacts.controls[index].controls.phone.value
            }
          } as LLApplicant
        }),
      childrenCount: form.controls.applicantsData.controls.numberOfChildren.value as number,
      children: form.controls.applicantsData.controls.children.value
        .map(value => {
          return {
            type: 'children',
            age: value.age as number,
            id: value.id as string
          }
        }),
      carCount: form.controls.applicantsData.controls.numberOfCars.value as number,
      privateLeaseCar: form.controls.applicantsData.controls.hasLeaseCar.value as boolean,
      monthlyPrivateLeaseCarCost: form.controls.applicantsData.controls.leaseCarCost.value ?? 0,
      monthlyChildrenCareCost: form.controls.applicantsData.controls.monthlyChildrenCareCost.value ?? 0,

      // Step 3
      property: {
        price: form.controls.properties.controls.newProperty.controls.price.value,
        downPayment: form.controls.properties.controls.newProperty.controls.downPayment.value,
        type: form.controls.properties.controls.newProperty.controls.type.value,
        fee: form.controls.properties.controls.newProperty.controls.fee.value
      },
      oldProperty: {
        status: form.controls.properties.controls.oldProperty.controls.futurePropertyOwnership.value,
        price: FormUtils.getValue(form.controls.properties.controls.oldProperty.controls.sellingPrice),
        loanAmount: FormUtils.getValue(form.controls.properties.controls.oldProperty.controls.loansAmount),
        type: form.controls.properties.controls.oldProperty.controls.type.value,
        fee: FormUtils.getValue(form.controls.properties.controls.oldProperty.controls.fee)
      },

      // Step 4
      studyLoan: FormUtils.getValue(form.controls.existentLoans.controls.studentLoan),
      loans: form.controls.existentLoans.controls.otherLoans.value
        .map((value): IApplicationLoan => {
          return {
            amount: value.amount as number,
            type: value.type as LoanType
          }
        }),

      // Step 6
      otherInfo: form.controls.otherInformation.controls.message.value,

      // Step 7
      terms: form.controls.termsData.controls.terms.value,
      manual: form.controls.termsData.controls.manual.value || !this.superFast
    }

    return {
      ...application,
      oldProperty: {
        ...application.oldProperty,
        futurePropertyOwnership:
          form.controls.properties.controls.oldProperty.controls.futurePropertyOwnership.value as TFuturePropertyOwnership
      },
      additionalLoans: form.controls.existentLoans.controls.hasOtherLoans.value,
      applicantsAreSpouses: form.controls.applicantsData.controls.areApplicantsMarried.value,
      haveCar: form.controls.applicantsData.controls.hasCar.value
    } as any
  }
}

<div class="holder">
  <div class="breadcrumbs">
    <span
      (click)="navigate(-1)"
      (keydown.enter)="navigate(-1)"
      (keydown.space)="navigate(-1)"
      class="link"
      tabindex="0"
    >
      Sparbanken Syd<span class="separator"> | </span>
    </span>

    @for (link of elements; track link) {
      @if (!$first) {
        <span class="last"> / </span>
      }
      @if (!$last) {
        <span
          (click)="navigate($index)"
          (keydown.enter)="navigate($index)"
          (keydown.space)="navigate($index)"
          class="link"
          tabindex="0"
        >
          {{ link }}
        </span>
      } @else {
        <span class="last">{{ link }}</span>
      }
    }
  </div>
</div>
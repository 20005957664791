import {LanelafteComponent} from './lanelafte/lanelafte.component'
import {
  LanelofteFetchComponent
} from './lanelofte-fetch/lanelofte-fetch.component'
import {LanelofteResComponent} from './lanelofte-res/lanelofte-res.component'

export default [
  {
    path: '',
    component: LanelafteComponent
  },
  {
    path: 'lanelofte-fetch',
    component: LanelofteFetchComponent
  },
  {
    path: 'lanelofte-res/:id',
    component: LanelofteResComponent
  }

]

<div class="public-section">
  <div class="public-section-inner-holder">
    <a class="public-logo-holder"
       href="https://internetbank.sparbankensyd.se/"
       target="_blank">
      <em class="material-icons">lock_outline</em>
      <span>Internetbanken</span>
    </a>
  </div>
</div>

<div class="main-section">
  <div class="main-section-inner-holder">
    <spb-logo></spb-logo>

    @if (canUseTestingTools) {
      <div class="menu-holder">
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
          <div class="developer-icon-holder">
            <mat-icon class="material-icons-outlined developer-icon">handyman
            </mat-icon>
          </div>
        </button>
      </div>
    }
  </div>
</div>

<mat-menu #menu="matMenu">
  <div class="menu-title-container" disabled="true" mat-menu-item>
    <p class="menu-title">Testing tools menu</p>
    <p class="menu-title-info">Only available in testing environment</p>
  </div>

  <button [matMenuTriggerFor]="reset" mat-menu-item>Reset</button>
  <button [matMenuTriggerFor]="configuration" mat-menu-item>Configuration
  </button>
  <button [matMenuTriggerFor]="preset" mat-menu-item>Presets</button>
  <button [routerLink]="['/', 'admin']" mat-menu-item>Admin</button>
</mat-menu>

<mat-menu #reset="matMenu">
  @for (option of removeOptionsMap | keyvalue; track option.key) {
    <button (click)="removeData.emit(option.key)"
            mat-menu-item>{{ option.value }}
    </button>
  }
</mat-menu>

<mat-menu #configuration="matMenu">
  <button (click)="toggleUc($event, 'ok')" mat-menu-item>
    @if (loanService.isMockUcActive$() === 'ok') {
      <mat-icon class="active">check_box</mat-icon>
    } @else {
      <mat-icon>check_box_outline_blank</mat-icon>
    }
    <span>Mock UC - Approved</span>
  </button>

  <button (click)="toggleUc($event, 'ko')" mat-menu-item>
    @if (loanService.isMockUcActive$() === 'ko') {
      <mat-icon class="active">check_box</mat-icon>
    } @else {
      <mat-icon>check_box_outline_blank</mat-icon>
    }
    <span>Mock UC - Rejected</span>
  </button>
</mat-menu>

<mat-menu #preset="matMenu">
  @for (option of preFillOptionsMap | keyvalue; track option.key) {
    <button (click)="preFillData.emit(option.key)"
            mat-menu-item>{{ option.value }}
    </button>
  }
</mat-menu>
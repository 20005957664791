import {KeyValuePipe} from '@angular/common'
import {Component, EventEmitter, Output} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {RouterLink} from '@angular/router'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {environment} from '../../../environments/environment'
import {LoanService, UcMockType} from '../../services/loan.service'

export enum PreFillOption {
  APPROVED,
  DENY_UC,
  DENY_KALP,
  DENY_INCOME,
  NON_SUPERFAST_OCCUPATION,
  NON_SUPERFAST_OLD_PROPERTY,
  NON_SUPERFAST_ALL_REASONS,
}

export enum RemoveOption {
  APPLICATION,
  PERSONAL_NUMBER,
  PROPERTY
}

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, RouterLink, KeyValuePipe, LogoComponent]
})
export class HeaderComponent {
  @Output() preFillData = new EventEmitter<PreFillOption>()
  @Output() removeData = new EventEmitter<RemoveOption>()

  public readonly preFillOptionsMap = new Map([
    [PreFillOption.APPROVED, 'Approved'],
    [PreFillOption.DENY_KALP, 'Deny KALP'],
    [PreFillOption.DENY_UC, 'Deny UC - Needs rejected mock UC'],
    [PreFillOption.DENY_INCOME, 'Deny Income - Needs approved mock UC'],
    [PreFillOption.NON_SUPERFAST_OCCUPATION, 'Non-superfast occupation wrong'],
    [PreFillOption.NON_SUPERFAST_OLD_PROPERTY, 'Non-superfast old property wrong'],
    [PreFillOption.NON_SUPERFAST_ALL_REASONS, 'Non-superfast all reasons']
  ])

  public readonly removeOptionsMap = new Map([
    [RemoveOption.APPLICATION, 'Application'],
    [RemoveOption.PERSONAL_NUMBER, 'Personnummer'],
    [RemoveOption.PROPERTY, 'Bostad']
  ])

  protected readonly canUseTestingTools = !environment.production

  /**
   * The constructor
   * @param loanService - Get the admin state
   */
  constructor(public loanService: LoanService) {
  }

  /**
   * Turns UC mocking on or off.
   */
  public toggleUc(event: Event, type: UcMockType) {
    event.stopPropagation()

    this.loanService.isMockUcActive$.update(value => {
      if (value === type) {
        return null
      } else {
        return type
      }
    })
  }
}

<form [formGroup]="form">
  <div class="mat-h3">Ansök med BankID</div>

  @if (!superFast) {
    <div class="super-fast">
      Baserat på de uppgifter {{ 'du/ni' | plural : hasCoApplicant }} har lämnat
      kan vi inte
      behandla {{ 'din/er' | plural : hasCoApplicant }} ansökan automatiskt.
      {{ 'Du/Ni' | plural : hasCoApplicant }}
      kan gå vidare genom att skicka {{ 'din/er' | plural : hasCoApplicant }}
      ansökan till en rådgivare.
    </div>
  }

  <p>
    {{ 'Jag/Vi' | plural : hasCoApplicant }} försäkrar att de uppgifter som
    lämnats är riktiga och fullständiga.
    {{ 'Jag/Vi' | plural : hasCoApplicant }} är också
    {{ 'medveten/medvetna' | plural : hasCoApplicant }} om att banken kan
    kontakta {{ 'mig/oss' | plural : hasCoApplicant }}
    för att inhämta ytterligare uppgifter.
  <p>
  <p>
    {{ 'Jag/Vi' | plural : hasCoApplicant }} godkänner att ansökan kommer att
    genomgå sedvanlig kreditprövning, varvid en kreditupplysning kommer att
    tas{{ './ på både sökande och medsökande.' | plural : hasCoApplicant }}
  </p>

  @if (superFast) {

    <p>{{ 'Min/Vår' | plural : hasCoApplicant }} ansökan kommer att bedömas
      automatiskt.</p>

  } @else {
    <p>{{ 'Min/Vår' | plural : hasCoApplicant }} ansökan kommer att behandlas av
      en rådgivare
      och {{ 'jag/vi' | plural : hasCoApplicant }} kommer att bli
      {{ 'kontaktad/kontaktade'| plural : hasCoApplicant }} inom 24 timmar
      (under kontorstid).</p>
  }

  <p>
    <mat-checkbox [formControl]="form.controls.terms"
                  color="primary"
                  ngDefaultControl>
      {{ 'Jag är införstådd/Vi är införstådda' | plural : hasCoApplicant }}
      med och godkänner ovanstående information och villkor.
    </mat-checkbox>
  </p>

  @if (superFast) {
    <p>
      <mat-checkbox [formControl]="form.controls.manual"
                    color="primary"
                    ngDefaultControl>
        {{ 'Jag/Vi' | plural : hasCoApplicant }} vill
        att {{ 'min/vår' | plural : hasCoApplicant }} ansökan behandlas av en
        handläggare.
      </mat-checkbox>
    </p>
  }

  @if (errorMessage) {
    <div class="spb-error">{{ errorMessage }}</div>
  }

  <p>När du skickar in ansökan legitimerar du dig med BankID.</p>

  <spb-bankid
    (accessToken)="signSuccess.emit($event)"
    [bankIdUrl]="environment.commonServiceUrl"
    [customButton]="(form.controls.manual.value || !superFast) ?
      'Ansök till rådgivare' : 'Ansök med automatisk kreditprövning'"
    [disabled]="!form.valid || !form.controls.terms.value"
    [domain]="environment.domain"
    mode="sign"
  ></spb-bankid>
</form>
<form [formGroup]="form">
  <div class="mat-h3">Kontaktuppgifter</div>
  <p>För att kunna behandla ansökan och återkomma behöver vi e-post och
    telefonnummer.</p>
  <p>
    <a
      href="https://www.sparbankensyd.se/om-sparbanken-syd/kundskydd/integritet-och-sekretess-gdpr/"
      target="_blank">
      Så här tar vi hand om dina personuppgifter</a> när du ansöker om lånelöfte
    hos oss.</p>

  @for (contact of contacts; track $index) {
    <div>
      <div [style.margin-top]="$first ? '' : '40px'"
           class="mat-h4">{{ $first ? 'Huvudlåntagare' : 'Medlåntagare' }}
      </div>

      <!-- Full name -->
      <p class="lead-text">För- och
        efternamn {{ $first ? 'huvudlåntagare' : 'medlåntagare' }}</p>
      <mat-form-field class="standard-full-width">
        <input
          [formControl]="contact.controls.fullName"
          [placeholder]="'För- och efternamn ' + ($first ? 'huvudlåntagare' : 'medlåntagare')"
          matInput
          minlength="5"
          type="text">
      </mat-form-field>

      <!-- Email -->
      <p class="lead-text">
        E-post {{ $first ? 'huvudlåntagare' : 'medlåntagare' }}</p>
      <mat-form-field class="standard-full-width">
        <input
          [formControl]="contact.controls.email"
          [placeholder]="'E-post ' + ($first ? 'huvudlåntagare' : 'medlåntagare')"
          matInput
          type="email">
      </mat-form-field>

      <!-- Phone -->
      <p class="lead-text">
        Telefon {{ $first ? 'huvudlåntagare' : 'medlåntagare' }}</p>
      <mat-form-field class="standard-full-width">
        <input
          [formControl]="contact.controls.phone"
          [placeholder]="'Telefon ' + ($first ? 'huvudlåntagare' : 'medlåntagare')"
          matInput
          maxlength="20"
          minlength="8"
          type="tel">
      </mat-form-field>
    </div>
  }
</form>
import {Component, Input} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'

export const getOtherInformationForm = () => new FormGroup(({
  message: new FormControl(undefined)
} as IOtherInformationForm))

export interface IOtherInformationForm {
  message: FormControl<string | undefined>
}

@Component({
  selector: 'spb-lanelafte-step-6',
  templateUrl: './lanelafte-step-6.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss', './lanelafte-step-6.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput]
})
export class LanelafteStep6Component {
  @Input() form = new FormGroup<IOtherInformationForm>(({} as IOtherInformationForm))
}

<form [formGroup]="form">
  <div class="mat-h3">Inkomst per månad, före skatt</div>
  <p class="lead-text">Ange inkomster, såsom lön, bidrag och andra regelbundna
    ersättningar. Ange
    belopp i kronor
    <strong>före</strong> skatt.</p>
  <p></p>

  @for (applicantIncome of applicantIncomes; track $index) {
    <p [style.margin-top]="$first ? '' : '40px'"
       class="lead-text">{{ $first ? 'Huvudlåntagare' : 'Medlåntagare' }}</p>

    <!-- Monthly income -->
    <mat-form-field class="standard-full-width">
      <input
        [formControl]="applicantIncome.controls.monthlyIncome"
        inputmode="numeric"
        matInput
        ngDefaultControl
        placeholder="Inkomst per månad"
        spbFormatNumber
        type="text">
    </mat-form-field>

    <!-- Occupation -->
    <p class="lead-text">Sysselsättning</p>
    <mat-form-field class="standard-full-width">
      <mat-select [formControl]="applicantIncome.controls.occupation"
                  ngDefaultControl>
        @for (occupation of occupationTypes | keyvalue; track occupation.key) {
          <mat-option [value]="occupation.key">
            {{ occupation.value }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <!-- Employer -->
    @if (isEmployerRequired(applicantIncome.controls.occupation.value)) {
      <p class="lead-text">Arbetsgivare</p>
      <mat-form-field class="standard-full-width">
        <label>
          <input
            [formControl]="applicantIncome.controls.employer"
            matInput
            minlength=2
            ngDefaultControl
            placeholder="Ange namnet på arbetsgivare"
            type="text"
          >
        </label>
      </mat-form-field>
    }

    <!-- Is A-Kassa member? -->
    <div [style.margin-bottom]="'15px'">
      <mat-checkbox [formControl]="applicantIncome.controls.isAKassaMember"
                    color="primary"
                    ngDefaultControl>
        Markera om {{ $first ? 'du' : 'medsökande' }} är medlem i A-kassa
      </mat-checkbox>
    </div>
  }
</form>
<div class="spb-holder">
  <spb-header></spb-header>
  <spb-breadcrumbs
    [links]="['https://www.sparbankensyd.se/lana-pengar/lanelofte/', '/lanelofte']"
    path="Låna | Lånelöfte | Hämta"></spb-breadcrumbs>
  <div class="spb-plain-content">
    <h1 class="mat-display-2">Hämta lånelöfte</h1>
    <p></p>
    <p>För att kunna hämta ditt lånelöfte måste du legitimera dig med
      BankID.</p>
    <div>
      @if (!showMissing) {
        <spb-bankid
          mode="login"
          [bankIdUrl]="environment.commonServiceUrl"
          [domain]="environment.domain"
          (accessToken)="onLoginSuccess($event)"
        ></spb-bankid>
      } @else {
        <p>Vi kan inte hitta ditt lånelöfte, du är välkommen
          <a [routerLink]="['lanelofte']">att ansöka igen</a>.</p>
      }
    </div>
  </div>
</div>

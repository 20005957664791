import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'

export const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const phoneNumber = control.value
  if (!(/^[\d\s+()-]{8,20}$/.test(phoneNumber))) {
    return {
      error: {
        message: 'Ange ett giltigt telefonnummer'
      }
    }
  }
  return null
}
import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../assets/package.json'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, FooterComponent]
})
export class AppComponent {
  public version = version.version
}
